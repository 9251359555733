var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - Pages" } }),
      _c("gov-heading", { attrs: { size: "xl" } }, [_vm._v("Pages")]),
      _c("gov-section-break", { attrs: { size: "m" } }),
      _vm.orderChangedMessage
        ? _c("gov-inset-text", [_vm._v(_vm._s(_vm.orderChangedMessage))])
        : _vm._e(),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "two-thirds" } },
            [
              _c(
                "ck-table-filters",
                { on: { search: _vm.onSearch } },
                [
                  _c(
                    "gov-form-group",
                    [
                      _c("gov-label", { attrs: { for: "filter[title]" } }, [
                        _vm._v("Page title")
                      ]),
                      _c(
                        "gov-grid-row",
                        [
                          _c(
                            "gov-grid-column",
                            { attrs: { width: "three-quarters" } },
                            [
                              _c("gov-input", {
                                attrs: {
                                  id: "filter[title]",
                                  name: "filter[title]",
                                  type: "search"
                                },
                                model: {
                                  value: _vm.filters.title,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "title", $$v)
                                  },
                                  expression: "filters.title"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "gov-grid-column",
                            { attrs: { width: "one-quarter" } },
                            [
                              _c(
                                "gov-button",
                                {
                                  attrs: { type: "button" },
                                  on: { click: _vm.clearSearch }
                                },
                                [_vm._v("Cancel")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "extra-filters" },
                    [
                      _c(
                        "gov-form-group",
                        [
                          _c(
                            "gov-label",
                            { attrs: { for: "filter[page_type]" } },
                            [_vm._v("Page type")]
                          ),
                          _c("gov-select", {
                            attrs: {
                              id: "filter[page_type",
                              name: "filter[page_type",
                              options: _vm.pageTypes
                            },
                            model: {
                              value: _vm.filters.page_type,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "page_type", $$v)
                              },
                              expression: "filters.page_type"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          ),
          _vm.auth.canAdd("page")
            ? _c(
                "gov-grid-column",
                { attrs: { width: "one-third" } },
                [
                  _c(
                    "gov-button",
                    {
                      attrs: {
                        to: { name: "pages-create-topic" },
                        success: "",
                        expand: ""
                      }
                    },
                    [_vm._v("Add a new Topic page")]
                  ),
                  _c(
                    "gov-button",
                    {
                      attrs: {
                        to: { name: "pages-create-landing" },
                        success: "",
                        expand: ""
                      }
                    },
                    [_vm._v("Add a new Landing page")]
                  ),
                  _c(
                    "gov-button",
                    {
                      attrs: {
                        to: { name: "pages-create-information" },
                        success: "",
                        expand: ""
                      }
                    },
                    [_vm._v("Add a new Information page")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.loading
        ? _c("ck-loader")
        : _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "two-thirds" } },
                [
                  _vm.searching
                    ? _c(
                        "gov-list",
                        { attrs: { bullet: true } },
                        _vm._l(_vm.pages, function(page) {
                          return _c(
                            "li",
                            { key: page.id },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(page.title) +
                                  "\n          "
                              ),
                              _vm.showView
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "gov-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "pages-show",
                                              params: { page: page.id }
                                            }
                                          }
                                        },
                                        [_vm._v("\n              View ")]
                                      ),
                                      _vm._v(" \n          ")
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              page.page_type === "landing"
                                ? _c("gov-tag", [_vm._v("Landing page")])
                                : _vm._e(),
                              _vm._v(" \n          "),
                              page.page_type === "topic"
                                ? _c("gov-tag", [_vm._v("Topic page")])
                                : _vm._e(),
                              _vm._v(" \n          "),
                              !page.enabled
                                ? _c(
                                    "gov-tag",
                                    { staticClass: "govuk-tag--grey" },
                                    [_vm._v("disabled")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c("ck-tree-list", {
                        key: "pageslist",
                        attrs: {
                          nodes: _vm.pagesTree,
                          edit: "pages-edit",
                          nodeType: "page",
                          bullet: true
                        },
                        on: {
                          "move-up": _vm.onMoveUp,
                          "move-down": _vm.onMoveDown
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "edit",
                              fn: function(editProps) {
                                return _vm.showView
                                  ? [
                                      _c(
                                        "gov-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "pages-show",
                                              params: {
                                                page: editProps.node.id
                                              }
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n            View\n          "
                                          )
                                        ]
                                      )
                                    ]
                                  : undefined
                              }
                            },
                            {
                              key: "status",
                              fn: function(statusProps) {
                                return [
                                  statusProps.node.page_type === "landing"
                                    ? _c("gov-tag", [_vm._v("Landing page")])
                                    : _vm._e(),
                                  _vm._v(" \n          "),
                                  !statusProps.node.enabled
                                    ? _c(
                                        "gov-tag",
                                        { staticClass: "govuk-tag--grey" },
                                        [_vm._v("disabled")]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }