var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("figure", [
    _vm.image.src || null
      ? _c("img", {
          class: _vm.imgClass,
          attrs: { src: _vm.image.src, alt: _vm.image.alt_text }
        })
      : _vm._e(),
    _vm.showAlt && _vm.image.alt_text
      ? _c(
          "figcaption",
          [_c("gov-hint", [_vm._v(_vm._s(_vm.image.alt_text))])],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }